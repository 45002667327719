.order {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-wrapper {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.order-price {
    width: 95%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.order-price small {
    color:rgba(0, 0, 0, 0.566)
}

.order-pricing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-pricing h4, .order-pricing p {
    margin: 10px 0px;
}

.order-price-button {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-price-button button {
    width: 250px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
    margin: 0px 20px;
}

.order button:disabled {
    color: #dcd7d7;
    background-color: #6f9321;
}

.order-info-wrapper {
    width: 80%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.order-info {
    width: 45%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.order h4 {
    font-size: 24px;
}

.order .input-group {
    width: 100%;
    height: 60px;
}

.order .input-group label {
    font-size: 16px;
}

.note {
    width: 75%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.note .input-group {
    height: 140px;
}

.note textarea {
    height: 100px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #33333345;
}

@media only screen and (max-width: 600px) {
    .order-info-wrapper {
        flex-direction: column;
    }

    .order-info {
        width: 95%;
    }
}