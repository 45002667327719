.productPopup-wrapper {
    position: absolute;

    width: 100vw;
    height: 100vh;

    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #00000034;
}

.productPopup {
    width: 80%;
    max-height: 80%;
    overflow: scroll;

    background-color: #fff;
    padding: 0 2.5% 2.5% 2.5%;
    border-radius: 5px;
}

.productPopup p {
    margin: 0px;
    text-align: justify;
}

.productPopup-button {
    width: 100px !important;
    height: 40px !important;
    border: none !important;
    color: #fff !important;
    background-color: #8CBD22 !important;
    font-weight: bold !important;
    font-size: 16px !important;
    border-radius: 5px !important;
    margin-top: 20px !important;
}

.homePagingButton {
    margin: 0px !important;
    width: auto !important; 
}