.carouselWrapper {
    width: 80vw;
    padding: 0 10vw;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.carousel {
    width: 100%;
}

.carousel-nav {
    width: 49%;
    height: 180px;

    display: flex;
    justify-content: center;
    align-items: center;

    overflow: hidden;
    position: relative;
}

.carousel-nav img {
    width: 180px;
    height: 180px;
}

.carousel-nav-ikea img {
    margin-top: -30px;
}

.carousel-nav-bricoman img {
    margin-top: -30px;
}

.carousel-left {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
}

.carousel-right {
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
}

.carousel-ikea {
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-ikea h1 {
    margin: 0px;
    color: #FBDA0C !important;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .carousel-nav {
        height: 130px;
    }

    .carousel-nav img {
        width: 130px;
        height: 130px;
    }

    .carousel-nav-ikea img {
        margin-top: -20px;
    }

    .carousel-nav-bricoman img {
        margin-top: -20px;
    }
}