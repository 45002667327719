.orders-table {
    color: #231f20;
}

.orders-row {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.orders-row:hover {
    background-color: #f3f3f3;
}

.orders-row-selected {
    background-color: #8CBD22;
}

.orders-row-selected:hover {
    background-color: #8CBD22;
}

.orders-row-selected p {
    color: white;
}

.orders-shipping p {
    margin: 0px;
}