.sitepopup-wrapper {
    position: absolute;

    width: 100vw;
    height: 100vh;

    top: 0;
    z-index: 1000000000000;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #000000a1;
}

.sitepopup {
    height: 200px;
    width: 60%;

    background-color: #fff;
    padding: 2.5%;
    border-radius: 5px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.sitepopup h4 {
    margin: 0px;
    text-align: center;
    color: #333;
}

.sitepopup button {
    width: 250px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .sitepopup {
        width: 80%;
    }
}