.payment {
    display: flex;
    flex-direction: column;
}

.payment-content {
    width: 100%;
    height: calc(100vh - 60px);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.payment-content span {
    color: #8CBD22;
}

.payment-content h1 {
    font-size: 72px;
}

.payment-content h4 {
    font-size: 32px;
    margin-bottom: 100px;
    margin-top: 0px;
}
