.product-page {
    width: 100%;
    min-height: 100vh;
}

.product-page .product {
    width: 80%;
    padding: 0 10%;

    display: flex;
    justify-content: center;
}

.product-page .product img {
    width: 100%;
}

.product-page .product .imgWrapper {
    width: 65%;
    display: flex;
}

.product-page .product .product-detail {
    width: 30%;
    display: flex;
    flex-direction: column;
    padding-left: 5%;
}

.product-page .product .product-detail h4 {
    margin: 0;
}

.product-page .product .product-detail p {
    margin: 0;
    color: #484848;
    line-height: 1.5rem;
    margin-top: 3px;
}

.product-page .product .product-detail h2 {
    margin: 0;
    margin-top: 10px;
}

.product .product-img {
    flex: none;
    margin-left: 0.625rem;
    margin-right: 0.625rem;
    width: calc((100% / 2));
}

.product .product-detail .product-colors {
    margin: 50px 0px 0px 0px;
}

.product .product-detail button {
    width: 200px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
}

.product-tools {
    display: flex;
    margin-top: 50px;
}

.product-tools input {
    width: 30px;
    padding-left: 10px;
    margin-right: 20px;
}

.colors {
    margin-top: 10px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;
    color: white;
}

.colors img {
    width: 75px !important;
    height: 75px;
}

.description {
    background-color: #484848;
    color: #fff;
    margin-left: 10%;
    width: 820px;
    height: 200px;
    margin-bottom: 100px;
    margin-top: 50px;
}

.product-detail .product-productsku {
    width: fit-content;
    color: #fff !important;
}