.cart {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.cart-wrapper {
    width: 80%;

    display: flex;
    justify-content: center;
}

.cart-price {
    margin-left: 25px;
    width: 30%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.cart-price small {
    color:rgba(0, 0, 0, 0.566)
}

.cart-pricing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-pricing h4, .cart-pricing p {
    margin: 10px 0px;
}

.cart-price-button {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-price-button button {
    width: 150px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
}

.cart-price-button button:disabled {
    background-color: #0a47a2;
    color: #bfbfbf;
}

@media only screen and (max-width: 600px) {
    .cart-wrapper {
        flex-direction: column;
    }

    .cart-price {
        margin-left: 0px;
        width: 95%;
    }
}