.navbar-wrapper {
    display: flex;
    align-items: center;
}

.navbar-item {
    display: flex;
    align-items: center;
}

.navbar-item svg {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .navbar-item p {
        display: none;
    }

    .admin-nav {
        margin-right: 5px !important;
    }
}