.popup-wrapper {
    position: absolute;

    width: 100vw;
    height: 100vh;

    top: 0;
    z-index: 1000000000000;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: #00000034;
}

.popup {
    width: 40%;

    background-color: #fff;
    padding: 2.5%;
    border-radius: 5px;
}

.popup p {
    margin: 0px;
    text-align: justify;
}

.popup button {
    width: 100px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
}

@media only screen and (max-width: 600px) {
    .popup {
        width: 80%;
    }
}