.cart-table {
    color: #231f20;
}

.cart-row {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.cart-row:hover {
    background-color: #f3f3f3;
}

.cart-row img {
    height: 75px;
}

.cart-cell-nopadding {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.cart-cell {
    padding: 4px !important;
}
