.nomatch {
    display: flex;
}

.nomatch-content {
    width: 100%;
    height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.nomatch-content span {
    color: #8CBD22;
}

.nomatch-content h1 {
    font-size: 72px;
}

.nomatch-content a {
    font-size: 32px;
    margin-right: 20px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 100px;
    color: #fff;
    background-color: #8CBD22;
    padding: 5px 10px;
    border-radius: 5px;
}
