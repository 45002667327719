.contact {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact-wrapper {
    width: 80%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.contact-price {
    width: 95%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.contact-price small {
    color:rgba(0, 0, 0, 0.566)
}

.contact-pricing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-pricing h4, .contact-pricing p {
    margin: 10px 0px;
}

.contact-price-button {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-price-button button {
    width: 250px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
}

.contact-info-wrapper {
    width: 80%;
    display: flex;
    justify-content: space-between;
    gap: 50px;
}

.contact-info {
    width: 100%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.contact h4 {
    font-size: 24px;
}

.contact .input-group {
    width: 100%;
    height: 60px;
}

.contact .input-group label {
    font-size: 16px;
}

.message {
    height: 140px !important;
}

.message textarea {
    height: 100px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #33333345;
}

.contact .contact-info-wrapper button {
    width: 100px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}