.footer {
    width: 100vw;
}

.footer-note {
    padding: 50px 20%;

    background-color: #fafafa;
    color: #484848;

    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    font-size: 12px;
}

.footer-note h4 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 50px;
}

.footer-note p {
    font-size: 18px;
    margin: 0;
}

.footer-hero {
    padding: 50px 10%;
    background-color: #383838;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-hero-item {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    /* width: calc(33% - 40px); */
}

.footer-hero-item h4 {
    font-size: 18px;
    margin: 0;
    margin-bottom: 10px;
}

.footer-hero-item p {
    margin: 0;
}

.footer-copyright {
    height: 50px;

    background-color: #231f20;
    color: white;

    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 12px;
}

.footer-nav {
    display: flex;
    justify-content: center;

    padding-top: 65px;
    padding-bottom: 49px;

    background-color: #000;
    color: #fff;
}

.footer-nav-item {
    margin: 0px 1%;
}

.footer-nav-item:nth-of-type(1) {
    width: 18%;
}

.footer-nav-item:nth-of-type(2) {
    width: 18%;
}

.footer-nav-item:nth-of-type(3) {
    width: 38%;
}


.footer-nav-item img {
    width: 100%;

    cursor: pointer;
}


.footer-nav-item h4 {
    font-size: 22px;
}


.footer-nav-item p {
    cursor: pointer;
}

@media only screen and (max-width: 600px) {
    .footer-note {
        padding: 50px 10%;
    }

    .footer-hero {
        padding: 40px 10%;
        flex-direction: column;
        text-align: center;
    }

    .footer-hero-item {
        padding: 30px 0px;
    }

    .footer-nav {
        flex-direction: column;
        padding: 0px 0px 20px;
    }

    .footer-nav-item {
        display: flex;
        flex-direction: column;
        width: 90% !important;
    }

    .footer-nav-item {
        height: auto;
        margin-bottom: 20px;
    }

    .footer-nav-item:first-of-type {
        margin-top: 20px;
        margin-left: 40px;
        margin-bottom: 0px;
    }

    .footer-nav-item img {
        height: auto;
        width: 50%;
    }

    .footer-nav-item h4 {
        margin: 10px 0px 10px 40px;
    }

    .footer-nav-item p {
        margin: 5px 0px 5px 40px;
    }

    .footer-nav-item svg {
        margin-left: 40px;
    }

    .footer h4 {
        font-size: 19px;
    }

    .footer p {
        font-size: 12px;
    }
}