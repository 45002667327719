.order-detail {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-detail-printing {
    width: 670px;
}

.order-detail-wrapper-printing {
    width: 100%;
    
    display: flex;
    align-items: center;
    flex-direction: column;
}

.order-detail-wrapper {
    width: 80%;

    display: flex;
    justify-content: center;
}

.order-detail-price-wrapper {
    margin-left: 25px;
    width: 25%;
}

.order-detail-price-wrapper-printing {
    width: 100%;
}

.order-detail-price-wrapper-printing .order-detail-price {
    width: 100%;
    padding: 0%;
}

.order-detail-price {
    width: 90%;
    padding: 5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.order-detail-price-printing {
    display: flex;
    justify-content: space-between;
}

.order-detail-price small {
    color:rgba(0, 0, 0, 0.566)
}

.order-detail-pricing {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-detail-pricing h4, .order-detail-pricing p {
    margin: 10px 0px;
}

.order-detail-price-button {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.order-detail-price-button button {
    width: 150px;
    height: 50px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 20px;
    border-radius: 5px;
}

.order-detail-price h4 {
    font-size: 24px;
}

.order-detail-price p {
    margin: 0px;
}

.order-detail-price .input-group {
    width: 100%;
    height: 30px;
}

.order-detail-price .input-group label {
    font-size: 16px;
}

.order-detail-toolbar {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.order-detail-toolbar-info {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    text-align: center;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    padding-top: 20px;
}

.order-detail-printing .order-detail-toolbar-info, .order-detail-wrapper {
    width: 100%;
}

.order-detail-printing h1 {
    font-size: 20px;
}

.order-detail-printing label {
    font-size: 20px;
}

.order-detail-printing p {
    margin: 5px 0px;
}

.order-detail-printing .input-group {
    height: 50px;
    margin-bottom: 5px;
}

.input-group-printing {
    display: flex;
    align-items: center;
    gap: 10px;
}

.input-group-printing p {
    margin: 0;
}

.order-detail-printing .order-detail-price-wrapper {
    margin-left: 2%;
    width: 30%;
}

.order-detail-printing .order-detail-price label, .order-detail-printing .order-detail-price p {
    font-size: 14px;
}

.cartlist-printing {
    width: 100%;

    display: flex;
    align-items: center;
    flex-direction: column;
}

.order-detail button {
    width: 80px;
    height: 30px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 20px;
}

.noshadow {
    box-shadow: none !important;
}