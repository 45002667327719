.admin {
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-wrapper {
    width: 80%;

    display: flex;
    flex-direction: column;
}

.admin-tools-wrapper {
    width: 100%;
}

.admin-tools {
    width: 95%;
    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}

.admin h4 {
    font-size: 24px;
}

.admin .input-group {
    width: 100%;
    height: 60px;
}

.admin .input-group label {
    font-size: 16px;
}

.admin-wrapper button {
    width: 100px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}

.admin textarea {
    height: 50px;
}


.cloneDropzone {
    margin-top: 25px;

    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.cloneInnerDropzone {
    width: 100%;
    height: 100%;
    background-color: #8CBD2247;
    color: #8CBD22;

    border: 3px dashed #8CBD22;
    border-radius: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.cloneInnerDropzone svg {
    margin: 25px 0px;
}

.cloneInnerDropzone p {
    margin-bottom: 10px;
}