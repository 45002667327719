.customer {
    width: 100%;
    min-height: 100vh;
}

.customer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.customer-info {
    width: 80%;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-info-card {
    width: 200px;
    height: 50px;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 2.5%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
}