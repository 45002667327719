.printhero {
    width: 100%;
}

.printhero-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.printhero-item {
    width: 25%;
    margin: 0px 4%;
}

.printhero-item img {
    width: 120px;
    height: 120px;
}

.printhero-item p {
    font-size: 12px ;
}