.privacy {
    width: 100%;
    min-height: 100vh;

    background-color: #eff5ff;
    color: #231f20;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.privacy-container {
    width: 80%;
}