.payment-options-container {
    font-family: 'Arial', sans-serif;
    padding: 20px;
  }
  
  .options-container {
    display: flex;
    flex-direction: column;
  }
  
  .option {
    padding: 5px 0px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top: none;
  }

  .option label {
    font-weight: normal;
    font-size: 16px;
  }

  .option:first-of-type {
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  .option:last-of-type {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  
  input[type="radio"] {
    margin-right: 8px;
  }
  
  .info {
    font-size: 14px;
    background-color: #f4f4f4;
    padding: 0px 20px;
    border-radius: 4px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease; /* Add transition for max-height */
  }
  
  /* Add a class for when the info is expanded */
  .info-expanded {
    max-height: 600px; /* Adjust the max-height to your preference */
    padding: 5px 20px;
    margin-bottom: -5px;
    margin-top: 5px;
  }
  