.creditnotes-table {
    color: #231f20;
}

.creditnotes-row {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.creditnotes-row:hover {
    background-color: #f3f3f3;
}

.creditnotes-shipping p {
    margin: 0px;
}