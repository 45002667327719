.orders {
    width: 100%;
    min-height: 100vh;
}

.page-title {
    width: 100%;
    text-align: center;
}

.orders-filters {
    display: flex !important;
    justify-content: space-between;
}

.orders-filters .input-group {
    width: 100%;
    height: 60px;
}

.orders-filters .input-group input {
    width: 200px;
}

.orders-filters .input-group label {
    font-size: 16px;
}

.orders-filters button {
    width: 100px;
    height: 40px;
    border: none;
    color: #fff;
    background-color: #8CBD22;
    font-weight: bold;
    font-size: 16px;
    border-radius: 5px;
}

.search-button-wrapper {
    display: flex;
    align-items: center;
}