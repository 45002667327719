.users-table {
    color: #231f20;
}

.users-row {
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.users-row:hover {
    background-color: #f3f3f3;
}