.login {
    width: 100%;
    height: 100vh;

    background-color: #eff5ff;
    color: #231f20;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-inner {
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.login form {
    width: 600px;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    border-radius: 5px;

    box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
}

.login form h4 {
    font-size: 50px;
    margin-bottom: 20px;
    margin-top: 0px;
}

.login button {
    border: none;
    background-color: #fff;
    font-weight: 700;
    font-size: 26px;
    border-radius: 10px;
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    width: 80%;
    height: 60px;
    margin-bottom: 20px;
}

.input-group input {
    height: 20px;
    padding: 5px;
    border-radius: 5px;
    border: solid 1px #33333345;
}

.login button {
    color: #fff;
    background-color: #8CBD22;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

label {
    font-weight: bold;
    font-size: 24px;
}

@media only screen and (max-width: 600px) {
    .login form {
        width: 100vw;
    }
}