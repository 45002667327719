.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
    color: #8CBD22 !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
    color: #8CBD22 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    color: #8CBD22;
}